import React from 'react';
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin
} from 'react-icons/fa';
import {GoLocation} from 'react-icons/go'
import {IoMdContact} from  'react-icons/io'
import {BiPhone} from 'react-icons/bi'
import {HiOutlineMail} from 'react-icons/hi'
import { TiSocialAtCircular } from "react-icons/ti";
import { animateScroll as scroll } from 'react-scroll';
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink
} from './FooterElements';

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };
  return (
    <FooterContainer id='contact'>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle> <GoLocation/> Localización</FooterLinkTitle>
              Paseo Granada 3800B, Colonia las Torres, CP 64930 Monterrey, Nuevo León, Mexico.
            </FooterLinkItems>
            <FooterLinkItems>
             <FooterLinkTitle><HiOutlineMail/> Contacto</FooterLinkTitle>
              info@blinkasociados.com
            </FooterLinkItems>
            <FooterLinkItems>
             <FooterLinkTitle><BiPhone/> Telefono</FooterLinkTitle>
              +52.811.065.4199
            </FooterLinkItems>
          </FooterLinksWrapper>
          <FooterLinksWrapper>
            
          </FooterLinksWrapper>
        </FooterLinksContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to='/' onClick={toggleHome}>
              blink asociados
            </SocialLogo>
            <WebsiteRights> blik asociados © 2022 All rights reserved.</WebsiteRights>
            <SocialIcons>
              <SocialIconLink href='https://www.facebook.com/profile.php?id=100080225411727' target='_blank' aria-label='Facebook'>
                <FaFacebook />
              </SocialIconLink>
              <SocialIconLink href='https://www.instagram.com/blinkasociados/' target='_blank' aria-label='Instagram'>
                <FaInstagram />
              </SocialIconLink>
              <SocialIconLink
                href='https://www.youtube.com/channel/UCSeZakbg99QvxaS1Qu-zK9A'
                target='_blank'
                aria-label='Youtube'
                rel='noopener noreferrer'
              >
                <FaYoutube />
              </SocialIconLink>
              <SocialIconLink
                target='_blank'
                aria-label='Twitter'
                href='//www.twitter.com/blinkasociados'
              >
                <FaTwitter />
              </SocialIconLink>
              <SocialIconLink href='https://www.linkedin.com/in/blink-asociados-9112a2237/' target='_blank' aria-label='Linkedin'>
                <FaLinkedin />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
