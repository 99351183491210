import React from 'react';
import Icon1 from '../../images/asesoria.jpg';
import Icon2 from '../../images/gestion.jpg';
import Icon3 from '../../images/capitalizar.jpg';
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP
} from './ServicesElements';

const Services = () => {
  return (
    <ServicesContainer id='services'>
      <ServicesH1>Nuestros Servicios</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Icon1} />
          <ServicesH2>Asesoría Financiera</ServicesH2>
          <ServicesP>
            Atención personalizada y profesional, entendiendo tus necesidades y brindando alternativas.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon2} />
          <ServicesH2>Gestión Crediticia</ServicesH2>
          <ServicesP>
            Apoyo durante todo el proceso crediticio, facilitando los procesos y comunicando cada avance.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon3} />
          <ServicesH2>Opciones en Capitalización</ServicesH2>
          <ServicesP>
            Nuestros convenios con las instituciones financieras nos permite encontrar la mejor opción para cada cliente.
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
