export const homeObjOne = {
  id: 'about',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Nosotros',
  headline: 'Asociados del Desarrollo',
  description:
    'Nuestro despacho cuenta con años de experiencia en diferentes sectores financieros, lo cual nos permite' +
    'brindar servicios de primer nivel y adecuadas a las necesidades de nuestros clientes.',
  buttonLabel: 'Get Started',
  imgStart: false,
  img: require('../../images/nosotros.jpg'),
  alt: 'Car',
  dark: true,
  primary: true,
  darkText: false
};

export const homeObjTwo = {
  id: 'identity',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Misión y Visión',
  headline: 'La mejor opción materializando sueños',
  description:
    'Nuestra misión es ser el brazo fuerte de las empresas mexicanas para su capitalización monetaria brindando nuestros' +
    ' servicios sin trasladar costos al cliente gracias a nuestros convenios, nuestra visión es ser la opción en asesoría crediticia.',
  buttonLabel: 'Learn More',
  imgStart: true,
  img: require('../../images/identidad.jpg'),
  alt: 'Piggybank',
  dark: false,
  primary: false,
  darkText: true
};


